const item1 = "/assets/7.png";
const item3 = "/assets/8.png";
const item5 = "/assets/9.png";
const item7 = "/assets/10.png";
const item9 = "/assets/11.png";
const item11 = "/assets/12.png";
export const slideOne = [
  item1,
  item5,
  item7,
  item3,
  item5,
  item3,
  item11,
  item7,
  item9,
  item1,
  item9,
  item11,
];
